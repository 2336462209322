var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "animated fadeIn" },
    [
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { attrs: { xs: "12", lg: "12" } },
            [
              _c(
                "b-card",
                [
                  _c("h4", { staticClass: "d-inline" }, [
                    _c("i", { staticClass: "fal fa-folder" }),
                    _vm._v(" All Project")
                  ]),
                  _c("hr"),
                  _c("v-client-table", {
                    staticClass: "statusTable",
                    attrs: {
                      columns: _vm.columns,
                      data: _vm.projectData,
                      options: _vm.options,
                      theme: _vm.theme,
                      id: "dataTable"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "status",
                        fn: function(props) {
                          return _c("div", {}, [
                            props.row.status === 0
                              ? _c("span", [
                                  _c(
                                    "button",
                                    {
                                      staticClass:
                                        "btn btn-sm btn-success w-100"
                                    },
                                    [_vm._v("Active")]
                                  )
                                ])
                              : _vm._e(),
                            props.row.status !== 0
                              ? _c("span", [
                                  _c(
                                    "button",
                                    {
                                      staticClass: "btn btn-sm btn-danger w-100"
                                    },
                                    [_vm._v("Inactive")]
                                  )
                                ])
                              : _vm._e()
                          ])
                        }
                      },
                      {
                        key: "projectname",
                        fn: function(props) {
                          return _c(
                            "div",
                            {},
                            [
                              _vm.$session.get("user") != "accountant"
                                ? _c(
                                    "router-link",
                                    {
                                      attrs: {
                                        to: {
                                          name: "Project Manager Project",
                                          params: { id: props.row.projectid }
                                        }
                                      },
                                      nativeOn: {
                                        click: function($event) {
                                          return _vm.setActiveProject(
                                            props.row.projectname
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(props.row.projectname) +
                                          " (" +
                                          _vm._s(props.row.ptn) +
                                          ")"
                                      )
                                    ]
                                  )
                                : _vm._e(),
                              _vm.$session.get("user") === "accountant" &&
                              !_vm.$route.meta.is_resource
                                ? _c(
                                    "router-link",
                                    {
                                      attrs: {
                                        to: {
                                          name: "Accountant Project",
                                          params: { id: props.row.projectid }
                                        }
                                      },
                                      nativeOn: {
                                        click: function($event) {
                                          return _vm.setActiveProject(
                                            props.row.projectname
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(props.row.projectname) +
                                          " (" +
                                          _vm._s(props.row.ptn) +
                                          ")"
                                      )
                                    ]
                                  )
                                : _vm._e(),
                              _vm.$session.get("user") === "accountant" &&
                              _vm.$route.meta.is_resource
                                ? _c(
                                    "router-link",
                                    {
                                      attrs: {
                                        to: {
                                          name: "Accountant Project Operation",
                                          params: { id: props.row.projectid }
                                        }
                                      },
                                      nativeOn: {
                                        click: function($event) {
                                          return _vm.setActiveProject(
                                            props.row.projectname
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(props.row.projectname) +
                                          " (" +
                                          _vm._s(props.row.ptn) +
                                          ")"
                                      )
                                    ]
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        }
                      }
                    ])
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }