<template>
  <div class="animated fadeIn">
    <b-row>
      <b-col xs="12" lg="12">
        <b-card>
          <h4 class="d-inline"><i class="fal fa-folder"></i> All Project</h4>
          <hr />
          <v-client-table class="statusTable" :columns="columns" :data="projectData" :options="options" :theme="theme"
            id="dataTable">
            <div slot="status" slot-scope="props">
              <span v-if="props.row.status === 0">
                <button class="btn btn-sm btn-success w-100">Active</button>
              </span>
              <span v-if="props.row.status !== 0">
                <button class="btn btn-sm btn-danger w-100">Inactive</button>
              </span>
            </div>
            <div slot="projectname" slot-scope="props">
              <router-link v-if="$session.get('user') != 'accountant'"
                v-on:click.native="setActiveProject(props.row.projectname)" :to="{
                  name: 'Project Manager Project',
                  params: { id: props.row.projectid },
                }">
                {{ props.row.projectname }} ({{ props.row.ptn }})</router-link>

              <router-link v-if="
                $session.get('user') === 'accountant' &&
                !$route.meta.is_resource
              " v-on:click.native="setActiveProject(props.row.projectname)" :to="{
                  name: 'Accountant Project',
                  params: { id: props.row.projectid },
                }">
                {{ props.row.projectname }} ({{ props.row.ptn }})</router-link>

              <router-link v-if="
                $session.get('user') === 'accountant' &&
                $route.meta.is_resource
              " v-on:click.native="setActiveProject(props.row.projectname)" :to="{
                  name: 'Accountant Project Operation',
                  params: { id: props.row.projectid },
                }">
                {{ props.row.projectname }} ({{ props.row.ptn }})</router-link>
            </div>
          </v-client-table>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import Vue from "vue";
import { ClientTable, Event } from "vue-tables-2";
import Datepicker from "vuejs-datepicker";
import jwt from "jwt-decode";

Vue.use(ClientTable);

export default {
  name: "AllProjects",
  components: {
    ClientTable,
    Event,
    Datepicker,
  },
  data() {
    return {
      projectname: "",
      p_start_date: "",
      columns: ["status", "projectname"],
      projectData: [
        {
          status: "",
        },
      ],
      options: {
        headings: {
          projectname: "Project Name",
          ptn: "Number",
        },
        sortable: ["status", "projectname", "creation_date"],
        filterable: ["status", "projectname", "creation_date"],
        sortIcon: {
          base: "fa",
          up: "fa-sort-up",
          down: "fa-sort-down",
          is: "fa-sort",
        },
        pagination: {
          chunk: 5,
          edge: false,
          nav: "scroll",
        },
      },
      theme: "bootstrap4",
      template: "default",
      useVuex: false,
      id: "",
      error: false,
      passworderror: false,
      projectData: [],
      unique: false,
      passlimit: false,
      wtsAssignmentData: [],
      wtsWellFilteredData: [],
      status: null,
      sendIdModal: null,
      pwid: "",
      projectid: "",
      acc_project: null,
    };
  },
  methods: {
    setActiveProject(val) {
      this.$session.set("projectname", val);
    },
    sendInfo(id) {
      false;
      var val = id;
      let data = this.wtsData;
      var id = data.findIndex(function (item, i) {
        return item.wtsid === val;
      });

      this.id = this.wtsData[id]["wtsid"];
      this.fname = this.wtsData[id]["fname"];
      this.lname = this.wtsData[id]["lname"];
      this.email = this.wtsData[id]["email"];
      this.status = this.wtsData[id]["status"];
      this.fax = this.wtsData[id]["fax"];
      this.phone = this.wtsData[id]["phone"];
    },
    sendWellId(pwid, projectid) {
      this.pwid = pwid;
      this.projectid = projectid;
    },
    findWell(id) {
      this.$http
        .get("/wts/get/wells/" + id)
        .then((response) => {
          if (response.status === 200) {
            this.wtsWellData = response.data.result;
            this.wtsWellFilteredData = this.wtsWellData.filter(
              (o) => o.projectid === id
            );
          }
        })
        .catch((error) => { });
    },
    clearErrors() {
      this.error = false;
      this.assignerror = false;
      this.editassignerror = false;
    },
    loadData() {
      this.$http
        .get("/wts/get/projects")
        .then((response) => {
          this.acc_project = 0;
          if (this.$route.name == "Accountant Project Operations") {
            this.acc_project = 1;
            this.projectData = response.data.result.filter(
              (x) => x.acc_project == this.acc_project
            );
          } else if (this.$route.name == "Accountant Projects") {
            this.projectData = response.data.result.filter(
              (x) => x.acc_project == 0
            );
          } else {
            this.projectData = response.data.result;
          }
        })
        .catch((error) => { });
    },
  },
  mounted: function () {
    this.loadData();
  },
};
</script>

<style>
.rt-date-picker-project input {
  padding: 0.3em 0.5em !important;
}
</style>
